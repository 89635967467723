import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { use100vh } from "react-div-100vh";
import { PrismicRichText } from "@prismicio/react";

// Components
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";
import { RandomProducts } from "../components/search/random-products";

// Context
import { CurrentLanguage } from "../components/context/current-language";
import { PageColor } from "../components/context/page-color";
import { HeaderColor } from "../components/context/header-color";
import { GatsbyImage } from "gatsby-plugin-image";

// SEO
import { PageSeo } from "../components/global/page-seo";

const Page = styled.div`
  &.page {
    margin: 180px 0 0 0;
  }

  & .content-container {
    padding: 0 50px;

    @media (max-width: 1445px) {
      padding: 0 40px;
    }

    @media (max-width: 999px) {
      padding: 0 30px;
    }

    @media (max-width: 800px) {
      margin: 0 0 100px 0;
      padding: 0 25px;
    }
  }

  & .title-container {
    grid-column: 1 / 13;

    &.section-title {
      margin: 60px 0 40px 0;
    }
  }

  & .products-container {
    grid-column: 1 / 13;
    grid-row-gap: 90px;

    @media (max-width: 1445px) {
      grid-row-gap: 60px;
    }
  }

  & .articles-container {
    grid-column: 1 / 13;
    grid-row-gap: 60px;
  }

  & .grid-12 {
    grid-column-gap: 50px;

    @media (max-width: 1445px) {
      grid-column-gap: 40px;
    }

    @media (max-width: 999px) {
      grid-column-gap: 30px;
    }

    @media (max-width: 800px) {
      grid-column-gap: 25px;
    }
  }
`;

const NoResults = styled.div`
  margin: 35px 0 0 0;

  & p {
    margin: 0;
  }
`;

const SingleSearchResult = styled.div`
  position: relative;

  &.product {
    grid-column: span 4;

    @media (max-width: 800px) {
      grid-column: span 3;
    }

    @media (max-width: 767px) {
      grid-column: span 6;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & .text-container {
      margin: 20px 0 0 0;

      & h4,
      & p {
        font-size: 14px;
        line-height: 25px;

        margin: 0;
      }
    }
  }

  &.article {
    grid-column: span 3;

    & .image-container {
      overflow: hidden;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .title-bar {
      display: flex;
      flex-direction: row;

      & p {
        margin: 0;
      }

      & .spacer {
        margin: 0 10px;
      }
    }

    & .text-container {
      margin: 20px 0 0 0;

      & .article-title {
        margin: 20px 0;

        @media (max-width: 999px) {
          font-size: 30px;
          line-height: 40px;
        }
      }

      & .article-excerpt {
        margin: 20px 0;
      }
    }

    @media (max-width: 800px) {
      margin: 0 40px 0 0;
      max-width: 250px;
    }
  }
`;

const SearchPage = ({ location }) => {
  const [searchResults, updateSearchResults] = useState([]);
  const [activeSearchResult, setActiveSearchResult] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);

  const [pageColor, setPageColor] = useContext(PageColor);
  const [headerColor, setHeaderColor] = useContext(HeaderColor);

  useEffect(() => {
    setPageColor(`#fff`);
    setHeaderColor(`#fff`);
  }, []);

  const height = use100vh();

  useEffect(() => {
    if (
      location !== null &&
      location.state !== null &&
      location.state !== undefined
    ) {
      if (location.state.searchResults !== undefined) {
        const sortedResults = location.state.searchResults
          .sort((a, b) => a.type.localeCompare(b.type))
          .filter((result) => {
            if (currentLanguage === `en`) {
              return result.lang === `en-gb`;
            } else {
              return result.lang === `fr-fr`;
            }
          });

        updateSearchResults(sortedResults);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [location]);

  if (
    location !== null &&
    location.state !== null &&
    location.state !== undefined
  ) {
    const filteredProducts =
      searchResults !== null && searchResults !== undefined && searchResults
        ? searchResults
            .filter((result) => result.type === `product`)
            .filter((result) => result.data.shopify_product !== null)
            .filter((result) => {
              if (currentLanguage === `en`) {
                return result.lang === `en-gb`;
              } else {
                return result.lang === `fr-fr`;
              }
            })
        : null;

    const filteredArticles =
      searchResults !== null && searchResults !== undefined && searchResults
        ? searchResults
            .filter((result) => result.type === `article`)
            .filter((result) => {
              if (currentLanguage === `en`) {
                return result.lang === `en-gb`;
              } else {
                return result.lang === `fr-fr`;
              }
            })
        : null;

    const products =
      filteredProducts !== null
        ? filteredProducts.map((product, index) => (
            <SingleSearchResult
              className="single-result product"
              key={`single_product_search_result_${index}_${product.id}`}
            >
              <div className="image-container">
                <Link
                  to={`/${currentLanguage}/products/${product.data.shopify_product.handle}/`}
                >
                  <AspectRatioImageContainer image={null} padding={140}>
                    <GatsbyImage
                      image={product.data.thumbnail.gatsbyImageData}
                      alt={product.data.shopify_product.title}
                    />
                  </AspectRatioImageContainer>
                </Link>
              </div>

              <div className="text-container">
                <PrismicRichText
                  field={product.data.title.richText}
                  components={{
                    heading1: ({ children, index }) => (
                      <h4 className="title uppercase gotham-light">
                        {children}
                      </h4>
                    ),
                  }}
                />

                <p className="price gotham-light">
                  {product.data.price_on_demand === false ? (
                    <p className="price">
                      {new Intl.NumberFormat("en-FR", {
                        style: "currency",
                        currency: "EUR",
                      }).format(product.data.shopify_product.variants[0].price)}
                    </p>
                  ) : (
                    <p className="price">
                      {currentLanguage === `en`
                        ? `Price on demand`
                        : `Prix sur demande`}
                    </p>
                  )}
                </p>
              </div>
            </SingleSearchResult>
          ))
        : null;

    const articles =
      filteredArticles !== null
        ? filteredArticles.map((article, index) => {
            const categories = article.data.categories
              .filter((category) => category.category.document !== null)
              .map((category, index) => (
                <span key={`single_article_category_${index}_${article.id}`}>
                  <Link to={category.category.document.url}>
                    {category.category.document.data.title.text}
                  </Link>
                </span>
              ));

            return (
              <SingleSearchResult
                className="single-result article"
                key={`single_article_search_result_${index}_${article.id}`}
              >
                <div className="image-container">
                  <Link to={article.url}>
                    <AspectRatioImageContainer image={null} padding={140}>
                      <img
                        srcSet={article.data.thumbnail.fluid.srcSet}
                        src={article.data.thumbnail.fluid.src}
                        alt={article.data.thumbnail.alt}
                      />
                    </AspectRatioImageContainer>
                  </Link>
                </div>

                <div className="text-container">
                  <div className="title-bar tag gotham-bold uppercase">
                    <p>Journal</p>
                    <p className="spacer">|</p>
                    <p>{categories}</p>
                  </div>

                  <PrismicRichText
                    field={article.data.title.richText}
                    components={{
                      heading1: ({ children, index }) => (
                        <h3
                          className="article-title"
                          key={`single_h3_${index}_${article.id}`}
                        >
                          {children}
                        </h3>
                      ),
                    }}
                  />
                  <PrismicRichText
                    field={article.data.excerpt_text.richText}
                    components={{
                      paragraph: ({ children, index }) => (
                        <p
                          className="article-excerpt"
                          key={`single_paragraph_${index}_${article.id}`}
                        >
                          {children}
                        </p>
                      ),
                    }}
                  />

                  <div className="call-to-action">
                    <Link to={article.url} className="uppercase small">
                      {currentLanguage === `en` ? `See More` : `Voir Plus`}
                    </Link>
                  </div>
                </div>
              </SingleSearchResult>
            );
          })
        : null;

    return (
      <>
        <Page className="page" height={height}>
          <div className="">
            {location !== null &&
            location.state !== null &&
            location.state !== undefined &&
            location.state.searchResults !== undefined ? (
              <>
                <PageSeo
                  title={
                    currentLanguage === `en` ? `Search Results` : `Résultats`
                  }
                  image={null}
                  description={null}
                  url={null}
                />

                <div className="content-container">
                  <div className="title-container">
                    <h1>
                      {currentLanguage === `en`
                        ? `We found ${searchResults.length} results for
                      your search: '${location.state.searchQuery}'`
                        : `Nous avons trouvé ${searchResults.length} résultats pour: '${location.state.searchQuery}'`}
                    </h1>
                  </div>

                  {filteredProducts.length >= 1 && (
                    <>
                      <div className="title-container section-title">
                        <p className="tag uppercase gotham-bold">
                          {filteredProducts.length} product
                          {filteredProducts.length > 1 && `s`} match
                          {filteredProducts.length === 1 && `es`} your search
                        </p>
                      </div>
                      <section className="products-container grid-12">
                        {products}
                      </section>
                    </>
                  )}

                  {filteredArticles.length >= 1 && (
                    <>
                      <div className="title-container section-title">
                        <p className="tag uppercase gotham-bold">
                          {filteredArticles.length} article
                          {filteredArticles.length > 1 && `s`} match
                          {filteredArticles.length === 1 && `es`} your search
                        </p>
                      </div>
                      <section className="articles-container grid-12">
                        {articles}
                      </section>
                    </>
                  )}
                </div>

                {location !== null &&
                  location.state !== null &&
                  location.state !== undefined &&
                  location.state.searchResults !== undefined && (
                    <>
                      {location.state.searchResults.length === 0 && (
                        <div className="content-container">
                          <div className="title-container">
                            {currentLanguage === `en` ? (
                              <h1>No results found</h1>
                            ) : (
                              <h1>Aucun résultat trouvé</h1>
                            )}
                          </div>

                          <RandomProducts currentLanguage={currentLanguage} />
                        </div>
                      )}
                    </>
                  )}
              </>
            ) : (
              <div className="content-container">
                <div className="title-container">
                  {currentLanguage === `en` ? (
                    <h1>No results found</h1>
                  ) : (
                    <h1>Aucun résultat trouvé</h1>
                  )}
                </div>

                <RandomProducts currentLanguage={currentLanguage} />
              </div>
            )}
          </div>
        </Page>
      </>
    );
  } else {
    return (
      <>
        <PageSeo
          title={currentLanguage === `en` ? `Search Results` : `Résultats`}
          image={null}
          description={null}
          url={null}
        />
        <Page className="page">
          <div className="content-container">
            <div className="grid-12">
              <div className="title-container">
                {currentLanguage === `en` ? (
                  <h1>No result found</h1>
                ) : (
                  <h1>Aucun résultat trouvé</h1>
                )}
              </div>
            </div>

            <RandomProducts currentLanguage={currentLanguage} />
          </div>
        </Page>
      </>
    );
  }
};

export default SearchPage;
