import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Link } from "gatsby";
import { useEffectOnce } from "react-use";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

const RandomProductsContainer = styled.div`
  margin: 120px 0 150px 0;

  & .title-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    margin: 0 0 30px 0;
    padding: 0 50px 0 0;

    @media (max-width: 1445px) {
      padding: 0 40px 0 0;
    }

    @media (max-width: 999px) {
      padding: 0 30px 0 0;
    }

    @media (max-width: 800px) {
      padding: 0 25px 0 0;
    }
  }

  & .single-product {
    & img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    & .text-container {
      margin: 20px 0 0 0;

      & h4,
      & p {
        font-size: 14px;
        line-height: 25px;

        margin: 0;
      }
    }
  }

  & .slide-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 50px;

    @media (max-width: 1445px) {
      grid-column-gap: 40px;
    }

    @media (max-width: 999px) {
      grid-column-gap: 30px;
    }

    @media (max-width: 767px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
    }
  }
`;

const SingleProduct = ({ product, currentLanguage }) => {
  return (
    <div className="single-product">
      <div className="image-container">
        <Link to={`/${currentLanguage}/products/${product.handle}`}>
          <AspectRatioImageContainer image={null} padding={140}>
            <img src={product.featuredImage.src} alt={product.title} />
          </AspectRatioImageContainer>
        </Link>
      </div>

      <div className="text-container">
        <h4 className="title uppercase gotham-light">{product.title}</h4>

        <p className="price gotham-light">
          {new Intl.NumberFormat("en-FR", {
            style: "currency",
            currency: "EUR",
          }).format(product.variants[0].price)}
        </p>
      </div>
    </div>
  );
};

export const RandomProducts = ({ currentLanguage }) => {
  const data = useStaticQuery(graphql`
    {
      allShopifyProduct {
        nodes {
          handle
          id
          title
          variants {
            price
          }
          featuredImage {
            src
          }
        }
      }
    }
  `);

  const [shuffledProducts, setShuffledProducts] = useState(
    data.allShopifyProduct.nodes
  );

  //https://gist.github.com/guilhermepontes/17ae0cc71fa2b13ea8c20c94c5c35dc4
  const shuffleArray = (arr) =>
    arr
      .map((a) => [Math.random(), a])
      .sort((a, b) => a[0] - b[0])
      .map((a) => a[1]);

  useEffectOnce(() => {
    setShuffledProducts(shuffleArray(data.allShopifyProduct.nodes));
  });

  const products = shuffledProducts
    .filter((product, index) => index <= 2)
    .map((product, index) => (
      <SingleProduct
        key={`single_search_page_product_row_${index}_${product.id}`}
        product={product}
        currentLanguage={currentLanguage}
      />
    ));

  return (
    <RandomProductsContainer>
      <div className="title-bar">
        <div className="carousel-title">
          <p className="tag uppercase gotham-bold">You might like</p>
        </div>
      </div>

      <div className="slide-container">{products}</div>
    </RandomProductsContainer>
  );
};
